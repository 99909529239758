export const CustomerService = {
    getData() {
        return [
            {
                id: 1000,
                name: 'Flood',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Benton, John B Jr',
                date: '2015-09-13',
                status: '287',
                verified: true,
                activity: 17,
                representative: {
                    name: 'System-v128',
                    image: 'ionibowcher.png'
                },
                balance: 70663
            },
            {
                id: 1001,
                name: 'Flood',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Chanay, Jeffrey A Esq',
                date: '2019-02-09',
                status: '242',
                verified: true,
                activity: 0,
                representative: {
                    name: 'System-v128',
                    image: 'amyelsner.png'
                },
                balance: 82429
            },
            {
                id: 1002,
                name: 'Flood',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Chemel, James L Cpa',
                date: '2017-05-13',
                status: '365',
                verified: true,
                activity: 63,
                representative: {
                    name: 'Network filter 3',
                    image: 'asiyajavayant.png'
                },
                balance: 28334
            },
            {
                id: 1003,
                name: 'Basic attack',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Feltz Printing Service',
                date: '2020-09-15',
                status: '627',
                verified: true,
                activity: 37,
                representative: {
                    name: 'Network filter 3',
                    image: 'xuxuefeng.png'
                },
                balance: 88521
            },
            {
                id: 1004,
                name: 'Flood',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Printing Dimensions',
                date: '2016-05-20',
                status: '192',
                verified: true,
                activity: 33,
                representative: {
                    name: 'Network filter 2',
                    image: 'asiyajavayant.png'
                },
                balance: 93905
            },
            {
                id: 1005,
                name: 'Flood',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Chapman, Ross E Esq',
                date: '2018-02-16',
                status: '400',
                verified: true,
                activity: 68,
                representative: {
                    name: 'Network filter 3',
                    image: 'ivanmagalhaes.png'
                },
                balance: 50041
            },
            {
                id: 1006,
                name: 'Flood',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Morlong Associates',
                date: '2018-02-19',
                status: '400',
                verified: true,
                activity: 54,
                representative: {
                    name: 'Client limit',
                    image: 'ivanmagalhaes.png'
                },
                balance: 58706
            },
            {
                id: 1007,
                name: 'Flood',
                country: {
                    name: 'United Kingodom',
                    code: 'uk',
                },
                company: 'Commercial Press',
                date: '2019-08-13',
                status: '400',
                verified: true,
                activity: 69,
                representative: {
                    name: 'Network filter 3',
                    image: 'onyamalimba.png'
                },
                balance: 26640
            },
            {
                id: 1008,
                name: 'Packet flood',
                country: {
                    name: 'Germany',
                    code: 'de'
                },
                company: 'Truhlar And Truhlar Attys',
                date: '2018-11-21',
                status: '133',
                verified: true,
                activity: 76,
                representative: {
                    name: 'Network database ceymikey',
                    image: 'ivanmagalhaes.png'
                },
                balance: 65369
            },
        ];
    },

    getCustomersSmall() {
        return Promise.resolve(this.getData().slice(0, 10));
    },

    getCustomersMedium() {
        return Promise.resolve(this.getData().slice(0, 50));
    },

    getCustomersLarge() {
        return Promise.resolve(this.getData().slice(0, 200));
    },

    getCustomersXLarge() {
        return Promise.resolve(this.getData());
    },

    getCustomers(params) {
        const queryParams = params
            ? Object.keys(params)
                .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&')
            : '';

        return fetch('https://www.primefaces.org/data/customers?' + queryParams).then((res) => res.json());
    }
};
