// src/App.js
import React from 'react';
import './App.css';
import CountUp from 'react-countup';
import TableComponent from './components/TableComponent';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="stats-container"> {/* Flex container */}
          <div className="stat-item">
            <h2>Attacks Stopped</h2>
            <CountUp end={1826} className="count-up" />
          </div>
          <div className="stat-item">
            <h2>Traffic received</h2>
            <CountUp end={2638} className="count-up" />
          </div>
          <div className="stat-item">
            <h2>Bots/Packets Blocked</h2>
            <CountUp end={1700} className="count-up" />
          </div>
        </div>
        <div className='space'></div>
        <div className="table-container"> {/* Container for centered table */}
          <TableComponent />
        </div>
      </header>
    </div>
  );
}

export default App;
